import { Attribute, Component, HostBinding, Input, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { CordovaService, StatusBar } from '@swan/lib/cordova';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { NavigationService } from '@yukawa/chain-base-angular-comp/shared';


const backIcon  = 'swan:navigation-arrow-left';
const closeIcon = 'swan:system-close';

@Component({
    selector   : 'lib-close-button',
    templateUrl: './close-button.component.html',
    styleUrls  : ['./close-button.component.scss'],
})
export class CloseButtonComponent implements OnInit
{
    @Input()
    routerLink: string | undefined;

    @Input()
    navigationExtras: NavigationExtras | undefined;

    @Input()
    autoMargin: boolean | undefined;

    @Input()
    style: 'close' | 'back' = 'close';

    private _backIcon  = backIcon;
    private _closeIcon = closeIcon;

    constructor(
        @Attribute('class') public classNames: string,
        private _navigationService: NavigationService,
    )
    {
    }

    public get backSvgIcon(): string
    {
        return this._backIcon;
    }

    public get closeSvgIcon(): string
    {
        return this._closeIcon;
    }

    @Input()
    public set backIcon(value: 'close' | 'arrow')
    {
        this._backIcon = value === 'close' ? closeIcon : backIcon;
    }

    @Input()
    public set closeIcon(value: 'close' | 'arrow')
    {
        this._closeIcon = value === 'close' ? closeIcon : backIcon;
    }

    @HostBinding('class')
    get class(): string
    {
        const classNames = this.classNames?.split(' ') || [];

        return classNames.join(' ');
    }

    public ngOnInit(): void
    {
    }

    public async close(): Promise<boolean>
    {
        return this._navigationService.back(this.navigationExtras);
    }
}
