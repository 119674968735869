<div class="bg-black flex flex-col h-full">
    <mat-card class="bg-white m-auto rounded-lg max-w-8 mat-elevation-z0 ">
        <mat-card-title class="mb-8">
            <img src="/assets/icons/logo.svg" alt="" class="h-8">
        </mat-card-title>
        <mat-card-content class="text-center">
            {{ "NOT_FOUND" | transloco }}
        </mat-card-content>
        <mat-card-actions>
            <div class="flex flex-col">
                <a [href]="getStoreLink()" class="block" mat-flat-button class="bg-black text-white rounded-full">
                    {{ "Download App" | transloco}}
                </a>
            </div>
        </mat-card-actions>
    </mat-card>
</div>