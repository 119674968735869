<div id="swan-product-video" class="h-full w-full bg-black">
    <!-- Player Theme -->
    <template id="swan-theme" *ngIf="content">
        <media-controller class="h-full w-full" breakpoints="sm:384 md:576">
            <div slot="top-chrome" style="color:white; width:100%">
                <!-- optional place for profile -->
                <div
                    style="display: flex; flex-direction: column; flex-grow: 1;padding:2em 1em; font-family: 'Bossa'; gap: 1em;    background: linear-gradient(180deg, var(--media-control-background), transparent);">
                    <div style="display:flex; flex-direction: row; line-height: 1.2rem; ">
                        <img *ngIf="content.author.imageId; else swanLogo" [src]="getImageUrl()"
                            [alt]="content.author.nickname"
                            style="border-radius: 50%; width: 50px; height: 50px; margin:0 1em;">
                        <ng-template #swanLogo>
                            <img src="/assets/icons/swan-icon.svg" alt="SWAN Logo"
                                style="border-radius: 50%; width: 50px; height: 50px; margin:0 1em;">
                        </ng-template>

                        <div style="">
                            <div style="font-weight: bold">{{ content.author.displayName }}</div>
                            <div><span style="font-size: .8em;">{{'@'+content.author.nickname}}</span> <a
                                    onclick="dispatchEvent(new CustomEvent('openApp'))" target="_blank"
                                    style="color:white;font-size: .8em">{{
                                    "FOLLOW" |
                                    transloco }}</a></div>
                        </div>
                    </div>
                    <div style="margin-bottom: 2em; line-height: 1.4em;">
                        {{ content.info.name | emoji }}
                    </div>
                </div>
            </div>

            <slot name="media" slot="media"></slot>

            <media-loading-indicator slot="centered-chrome" noautohide></media-loading-indicator>
            <media-play-button slot="centered-chrome" class="rounded-lg"
                style="border-radius: 40px; backdrop-filter: blur(3px);">
                <span slot="play" style="width: 50px; height: 24px; ">
                    <svg viewBox="0 0 24 24" style="width:100%; height:100%">
                        <path d="M18.3599 12L8 19.9691V4.03086L18.3599 12Z" stroke="currentColor" stroke-width="2"
                            fill="none" />
                    </svg>
                </span>
                <span slot="pause" style="width: 50px; height: 24px;">
                    <svg style="color:white" viewBox="0 0 24 24" style="width:100%; height:100%">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 4H7V20H9V4ZM17 4H15V20H17V4Z"
                            fill="currentColor" />
                    </svg>
                </span>
            </media-play-button>
            <div slot="bottom-chrome">

            </div>
            <!-- need to use dispatchEvent because (click) handlers do not work from shadow DOM -->
            <media-control-bar
                style="display: flex; justify-content: end; flex-flow: column; align-items: flex-end; gap: 1em; margin-right: 1em; margin-bottom: 1em;">
                <a onclick="document.dispatchEvent(new CustomEvent('openApp'))"
                    style="background-color: var(--media-control-background); padding: .5em; border-radius: 40px; color: white; display: flex; flex-direction: column; line-height: 1.4em; align-items: center; text-decoration: none; backdrop-filter: blur(3px); cursor:pointer">
                    <svg id="heart-outline" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M12 4.52779C10.9385 3.57771 9.53671 3 8 3C4.68629 3 2 5.68629 2 9C2 14 6 18 12 21C18 18 22 14 22 9C22 5.68629 19.3137 3 16 3C14.4633 3 13.0615 3.57771 12 4.52779ZM12 18.7478C17.2617 15.9221 20 12.5973 20 9C20 6.79086 18.2091 5 16 5C14.9742 5 14.0428 5.38348 13.3338 6.01806L12 7.21187L10.6662 6.01806C9.95717 5.38348 9.02575 5 8 5C5.79086 5 4 6.79086 4 9C4 12.5973 6.73827 15.9221 12 18.7478Z"
                            fill="currentColor" />
                    </svg>
                    <span *ngIf="content.stats.likes > 0">{{content.stats.likes}}</span>
                </a>
                <a onclick="document.dispatchEvent(new CustomEvent('openApp'))"
                    style="background-color: var(--media-control-background); padding: .5em; border-radius: 40px; color: white; backdrop-filter: blur(3px); cursor:pointer">
                    <svg id="comment" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.5 3C4.80558 3 1 6.80558 1 11.5C1 13.9019 1.99625 16.0711 3.59808 17.617L3.59808 22L7.48031 19.7586C8.12767 19.9164 8.80407 20 9.5 20H14.5C19.1944 20 23 16.1944 23 11.5C23 6.80558 19.1944 3 14.5 3H9.5ZM3 11.5C3 7.91015 5.91015 5 9.5 5H14.5C18.0899 5 21 7.91015 21 11.5C21 15.0899 18.0899 18 14.5 18H9.5C5.91015 18 3 15.0899 3 11.5Z"
                            fill="currentColor" />
                    </svg>

                </a>
                <a onclick="document.dispatchEvent(new CustomEvent('openApp'))"
                    style="background-color: var(--media-control-background); padding: .5em; border-radius: 40px; color: white; backdrop-filter: blur(3px); cursor:pointer">
                    <svg id="favorite" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6 7C6 5.34315 7.34315 4 9 4H15C16.6569 4 18 5.34315 18 7V19.3967L13.3196 17.1043C12.4871 16.6965 11.5129 16.6965 10.6804 17.1043L6 19.3967V7Z"
                            stroke="currentColor" stroke-width="2" />
                    </svg>
                </a>
                <a onclick="document.dispatchEvent(new CustomEvent('openApp'))"
                    style="background-color: var(--media-control-background); padding: .5em; border-radius: 40px; color: white; backdrop-filter: blur(3px); cursor:pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                        fill="currentColor">
                        <path
                            d="M240-40q-33 0-56.5-23.5T160-120v-440q0-33 23.5-56.5T240-640h120v80H240v440h480v-440H600v-80h120q33 0 56.5 23.5T800-560v440q0 33-23.5 56.5T720-40H240Zm200-280v-447l-64 64-56-57 160-160 160 160-56 57-64-64v447h-80Z" />
                    </svg>
                </a>
                <media-captions-menu hidden anchor="left" style="margin-right:2em;"></media-captions-menu>
                <media-captions-menu-button style="border-radius: 50%;"></media-captions-menu-button>
            </media-control-bar>
            <media-control-bar>
                <media-time-range class="bg-transparent"></media-time-range>
            </media-control-bar>
            <media-control-bar>
                <media-mute-button></media-mute-button>
                <media-volume-range></media-volume-range>
                <span class="spacer" style="flex-grow:1; background-color: var(--media-control-background);"></span>
                <media-time-display showduration remaining></media-time-display>
                <!-- <media-captions-menu hidden anchor="auto"></media-captions-menu>
                <media-captions-menu-button></media-captions-menu-button> -->
                <!--<media-pip-button></media-pip-button>-->
                <!--<media-fullscreen-button></media-fullscreen-button>-->
            </media-control-bar>
            <div style="width:100%;height:.5em; background-color: var(--media-control-background);"></div>
        </media-controller>
    </template>
    <!-- mux player impl -->
    <div class="mux-container h-full w-full flex p-8 relativ" *ngIf="playback; else liveShowTemplate">
        <mux-player #muxVideo class="aspect-video" crossOrigin theme="swan-theme" class="z-10">
        </mux-player>
        <canvas class="absolute" #backdrop class="hidden"></canvas>
    </div>
    <ng-template #liveShowTemplate>
        <div class="h-full w-full flex flex-col items-center justify-items-end text-white">

            <div
                style="display: flex; flex-direction: column; flex-grow: 1;padding:1em; font-family: 'Bossa'; gap: 1em;    background: linear-gradient(0, var(--media-control-background), transparent);">
                <div class="-z-10 object-contain">
                    <img [src]="content.thumbnail" alt="content.info.name">
                </div>
                <div style="display:flex; flex-direction: row; line-height: 1.2rem; ">
                    <img src="/assets/icons/swan-icon.svg" alt="SWAN Logo"
                        style="border-radius: 50%; width: 50px; height: 50px; margin:0 1em;">
                    <div style="">
                        <div style="font-weight: bold">{{ content.author.displayName }}</div>
                        <div><a (click)="getStoreLink()" target="_blank" style="color:white;font-size: .8em">{{
                                "FOLLOW" |
                                transloco }}</a></div>
                    </div>
                </div>
                <div>
                    {{ content.info.name}}
                </div>
                <div *ngIf="content.liveStream" class="mb-2">
                    {{"Live Stream will start" | transloco }} {{ content.liveStream.scheduledTime | date: 'medium' }}
                </div>
            </div>
        </div>
    </ng-template>

</div>