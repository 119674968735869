<div id="splash-carousel" class="grow h-96 left-0 right-0 relative">
    <app-carousel class="h-full" id="carousel" [autoplay]="false" [maintainAspectRatio]="false"
        [hideArrows]="(variantImages.length || 0) <= 1" [hideIndicators]="(variantImages.length || 0) <= 1"
        [useMouseWheel]="true" #carousel>
        <app-carousel-slide *ngFor="let image of variantImages" [image]="image.url || ''" bgSize="contain">
        </app-carousel-slide>
    </app-carousel>
    <a [href]="getStoreLink()" mat-icon-button class="text-black absolute bottom-2 right-4 z-99">
        <mat-icon svgIcon="mat_outline:ios_share" style="fill:#000"></mat-icon>
    </a>
    <a [href]="getStoreLink()" mat-icon-button class="text-black absolute top-2 right-4 z-99">
        <mat-icon svgIcon="mat_outline:favorite" style="fill:#000"></mat-icon>
    </a>
</div>
<div class="m-4">
    <div id="vendor" class="flex items-center gap-1 mb-4">
        <div class="profile-img relative flex-grow-0">
            <img *ngIf="product.vendor?.profile?.imageId; else defaultLogo"
                [src]="getImageUrl()"
                [alt]="product.vendor?.profile?.displayName || 'SWAN Vendor'"
                style="border-radius: 50%; width: 40px; height: auto; margin: 6px;">

            <ng-template #defaultLogo>
                <img src="/assets/icons/swan-icon.svg" alt="SWAN Logo"
                    style="border-radius: 50%; width: 40px; height: auto; margin: 6px;">
            </ng-template>
        </div>
        <div>
            <h2 class="flex items-center gap-2 font-bold">{{product.vendor?.profile?.displayName}} <mat-icon
                    *ngIf="product.vendor?.profile?.verified || true" svgIcon="swan:verified"
                    class="min-w-0 min-h-0"></mat-icon></h2>
        </div>
    </div>
    <div id="description" class="description">
        <h1 class="text-xl">{{product.titleOverride?product.titleOverride:product.title}}</h1>

    </div>
    <div id="variantSelect" *ngIf="(product.variants?.length || 0) > 1">
        <mat-form-field appearance="outline" class="w-full p-0">
            <mat-select [(ngModel)]="selectedVariant" (selectionChange)="carousel.slideTo(0)">
                <mat-select-trigger *ngIf="selectedVariant">
                    <div class="flex items-center gap-4">
                        <div class="swatch swatch-small inline-block" *ngIf="hasColorOptions()"
                            [ngStyle]="{'background-color':selectedVariant.options?.[0]?.color}"></div>
                        <span>{{selectedVariant.title}}</span>
                    </div>
                </mat-select-trigger>
                <mat-option *ngFor="let variant of product.variants" [value]="variant">
                    <div class="flex items-center gap-4">
                        <div class="swatch inline-block" [ngStyle]="{'background-color':variant.options?.[0]?.color}">
                        </div>
                        {{variant.title}}
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div id="swatch-wrapper" class="relative">
            <div id="swatches" class="flex gap-2 flex-nowrap relative" *ngIf="hasColorOptions()">
                <div *ngFor="let variant of product.variants" class="swatch"
                    [class.selected]="selectedVariant === variant" [class.disabled]="isAvailable(variant) === false"
                    (click)="selectedVariant = variant; carousel.slideTo(0)"
                    [ngStyle]="{'background-color':variant.options?.[0]?.color}">
                </div>
            </div>
            <div class="overlay"></div>
        </div>
    </div>
    <div id="price" class="mt-4 mb-4">
        <h2 class="text-xl font-bold">{{selectedVariant.price | currency: 'EUR'}}</h2>
        <div *ngIf="unitPrice()" class="text-sm">{{ variantPriceReference() }}</div>
    </div>
    <a [href]="getStoreLink()" mat-flat-button class="bg-black text-white w-full mt-4 mb-6">{{"Add to bag" |
        transloco}}</a>
    <h3 class="mb-2" *ngIf="!!productDesc">Description</h3>
    <p [innerHTML]="productDesc | emoji | marked"></p>
</div>
