import { IEnvironment as BaseEnvironment } from '@yukawa/chain-base-angular-client';
import { Nullable } from 'simplytyped';


export enum AppEnvironment
{
    admin = 'admin',
    app   = 'app'
}

export interface IEnvironment extends BaseEnvironment
{
    app: AppEnvironment;
    wsEndpoint: string;
    _wsEndpoint: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    RTCPeerConfiguration: {
        iceServers: [
            {
                urls: Array<string>;
            },
        ];
    };

    /**
     * `true` - Mirror App
     * `false` - Mobile App
     * `null` - Admin App
     */
    mirror: Nullable<boolean>;
}
