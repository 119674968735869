import { PublicProfile } from '@swan/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { IQueryTableEntryDetail, ISelectOption } from '@yukawa/chain-base-angular-comp/query-table';
import { AutocompleteSearchFunction } from '@yukawa/chain-base-angular-comp/shared';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { UserFilter } from '@yukawa/chain-main-angular-core';
import { lastValueFrom, map, Subject } from 'rxjs';
import { Nullable } from 'simplytyped';
import { User } from './user.model';
import { UserService } from './user.service';


export const userAutocompleteSearch = (
    value: Nullable<PublicProfile>,
    detail: Partial<IQueryTableEntryDetail>): AutocompleteSearchFunction =>
{
    const subject = new Subject<Array<ISelectOption<string, PublicProfile>>>();
    detail.autocompleteOptions$ = subject;
    return async (filter: string | null): Promise<void> =>
    {
        // prevent empty filter will return all users
        if (!filter) {
            subject.next([]);
            return;
        }

        subject.next(await lastValueFrom(AppInjector.get(UserService).queryUser({
            keyword: `*${filter}*`,
            pager  : {
                firstResult: 0,
                orderBy : 'username',
                orderDir: 'ASC',
                pageSize   : 10,
            } as UserFilter,
        } as UserFilter).pipe(
            map((users: QueryResult<User>) =>
            {
                const autocompleteOptions = users.items.map((user: User) => ({
                    name       : user.username,
                    value      : user.username,
                    objectValue: user.username === value?.username
                        ? value
                        : {
                            username: user.username,
                        } as PublicProfile,
                } as ISelectOption<string, PublicProfile>));

                // Display also unavailable user items
                if (value && !autocompleteOptions.some(option => option.name === value.username)) {
                    autocompleteOptions.push({
                        name       : value.username as string,
                        value      : value.username as string,
                        objectValue: {
                            username: value,
                        } as PublicProfile,
                    });
                }

                return autocompleteOptions;
            }),
        )));
    };
};
