import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterestFilter } from '@swan/lib/domain';
import { AppInjector, CachedRepoEntityService, ConfigService } from '@yukawa/chain-base-angular-client';
import { Roles } from '@yukawa/chain-main-angular-session';
import { lastValueFrom, Observable } from 'rxjs';
import { Nullable } from 'simplytyped';
import { InterestInfo } from './interest-info.entity';
import { IInterestInfo as IInterestInfo } from './interests.types';
import { UserService } from './user.service';


@Injectable()
export class InterestInfoService extends CachedRepoEntityService<InterestInfo, InterestFilter, string>
{
    constructor(http: HttpClient, configService: ConfigService)
    {
        super(http, configService, 'interestUrl', entity => entity.id);
    }

    get interests(): Array<InterestInfo>
    {
        return Array.from(this.repository.store.values());
    }

    getInterestsTree(): Observable<Array<InterestInfo>>
    {
        return this.repository.add(this.http.get<Array<IInterestInfo>>(
                AppInjector.get(UserService).hasRole(Roles.admin)
                    ? `${this.configService.getValue('baseUrl') + this.configService.getValue('interestAdminUrl')}/tree`
                    : this.formatServiceUrl('/tree'),
            ),
        );
    }

    async loadInterests(): Promise<Array<InterestInfo>>
    {
        this.repository.clear();
        await lastValueFrom(this.getInterestsTree());
        return this.interests;
    }

    findInterest(id: string, interest?: IInterestInfo): Nullable<IInterestInfo>
    {
        if (interest?.id === id) {
            return interest;
        }

        for (const _interest of (interest ? interest.interests : this.interests)) {
            const findInterest = this.findInterest(id, _interest);
            if (findInterest) {
                return findInterest;
            }
        }

        return null;
    }

    protected override keyForEntityCache(json: InterestInfo): string
    {
        return json.key;
    }

    protected createInstanceFrom(json: InterestInfo): InterestInfo
    {
        return new InterestInfo(json);
    }
}
