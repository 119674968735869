import { Gender, NotificationType, PublicProfile, SkinType } from '@swan/lib/domain';
import { AppInjector, Entity, enumKeys } from '@yukawa/chain-base-angular-client';
import { Change, Image as IImage, ImageSize } from '@yukawa/chain-base-angular-domain';
import { SessionService } from '@yukawa/chain-main-angular-session';
import moment from 'moment';
import { lastValueFrom } from 'rxjs';
import { Nullable } from 'simplytyped';
import { Image } from './image.entity';
import { ImageService } from './image.service';
import { InterestInfo } from './interest-info.entity';
import { InterestInfoService } from './interest-info.service';
import { ProfileFollowService } from './profile-follow.service';
import { Profile as IProfile, ProfilePersonalizeSteps } from './profile.model';
import { UserService } from './user.service';


export { ProfilePersonalizeSteps };

export class Profile extends Entity<IProfile> implements IProfile
{
    public readonly bgImageId!: string;
    public businessProfile!: boolean;
    public change!: Required<Change>;
    public created!: Required<Change>;
    public currentOffersConsent!: boolean;
    public dateOfBirth!: Date;
    public description!: string;
    public displayName!: string;
    public earlyAccessConsent!: boolean;
    public gender!: Gender;
    public readonly imageId!: string;
    public interests!: string[];
    public latestNewsConsent!: boolean;
    public nickname!: string;
    public skinType!: SkinType;
    public skipNotifications!: Array<NotificationType>;
    public username!: string;
    public verified!: string;
    public verifiedDate!: Date;
    public website!: string;
    private _bgImageUrl?: string;
    private _imageUrl?: string;

    constructor(
        initialData: IProfile | PublicProfile,
    )
    {
        super(initialData as IProfile);
    }

    //region Public Properties
    /* eslint-disable @typescript-eslint/member-ordering */

    public get bgImage(): Nullable<Image>
    {
        return Array.from(AppInjector.get(ImageService).repository.store.values())
            .find(_image => _image.related.id === this.username && _image.related.entity === 'ProfileBackground') as Image;
    }

    public get bgImageUrl(): Nullable<string>
    {
        if (this.bgImageId) {
            return this._bgImageUrl ??= AppInjector.get(ImageService).downloadUrl(
                {
                    imageId: Number(this.bgImageId),
                    size   : ImageSize.DEFAULT,
                },
            );
        }
        const image = this.bgImage;

        if (!image) {
            return null;
        }

        return this._imageUrl ??= AppInjector.get(ImageService).downloadUrl(image, {
            size: ImageSize.DEFAULT,
        });
    }

    public get pushNotifications(): Nullable<boolean>
    {
        if (this.currentOffersConsent == null && this.earlyAccessConsent == null && this.latestNewsConsent == null) {
            return null;
        }
        else if (this.currentOffersConsent != null || this.earlyAccessConsent != null || this.latestNewsConsent != null) {
            return this.currentOffersConsent || this.earlyAccessConsent || this.latestNewsConsent;
        }
        return false;
    }

    public set pushNotifications(value: Nullable<boolean>)
    {
        this.currentOffersConsent = !!value;
        this.earlyAccessConsent   = !!value;
        this.latestNewsConsent    = !!value;
    }

    /**
     * Returns a value indicating the overall profile completion progress.
     */
    public get personalized(): number
    {
        const map   = this.calculateProgressDetail();
        let average = 0;

        map.forEach((value, key1) =>
        {
            average += value;
        });
        average /= map.size;

        return Math.round(average);
    }

    public get progressDetail(): Map<ProfilePersonalizeSteps, number>
    {
        return this.calculateProgressDetail();
    }

    public get key(): string
    {
        return this.username;
    }

    public get imageMetadata(): IImage
    {
        return {
            category: 'main',
            related : {
                id    : String(this.username),
                entity: 'Profile',
                module: 'swan',
            },
        };
    }

    public get image(): Nullable<Image>
    {
        return Array.from(AppInjector.get(ImageService).repository.store.values())
            .find(_image => _image.related.id === this.username) as Image;
    }

    public get imageUrl(): Nullable<string>
    {
        if (this.imageId) {
            return this._imageUrl ??= AppInjector.get(ImageService).downloadUrl(
                {
                    imageId: Number(this.imageId),
                    size   : ImageSize.DEFAULT,
                },
            );
        }
        const image = this.image;

        if (!image) {
            return null;
        }

        return this._imageUrl ??= AppInjector.get(ImageService).downloadUrl(image, {
            size: ImageSize.DEFAULT,
        });
    }

    public get interestInfos(): Array<InterestInfo>
    {
        return AppInjector.get(InterestInfoService).interests.reduce(
            (current: Array<InterestInfo>, next: InterestInfo): Array<InterestInfo> =>
            {
                const interests = next.selectedChildren;
                if (interests.length > 0) {
                    current.push(...interests);
                }
                else if (next.selected) {
                    current.push(next);
                }
                return current;
            },
            new Array<InterestInfo>(),
        );
    }

    public get isAdmin(): boolean
    {
        return this.username === 'admin' || !!AppInjector.get(SessionService).session?.user?.isAdmin;
    }

    public get isCurrentUser(): boolean
    {
        return AppInjector.get(UserService).user?.username === this.username;
    }

    public get following(): boolean
    {
        return !!AppInjector.get(ProfileFollowService).repository.get(this.username)?.following;
    }

    public get nextPersonalizeStep(): ProfilePersonalizeSteps
    {
        for (const _step of enumKeys(ProfilePersonalizeSteps)) {
            switch (ProfilePersonalizeSteps[_step]) {
                case ProfilePersonalizeSteps.name:
                    if (this.displayName?.length) {
                        continue;
                    }
                    break;
                case ProfilePersonalizeSteps.interests:
                    if (this.interests?.length) {
                        continue;
                    }
                    break;
                case ProfilePersonalizeSteps.gender:
                    if (this.gender) {
                        continue;
                    }
                    break;
                case ProfilePersonalizeSteps.birthdate:
                    if (this.dateOfBirth) {
                        continue;
                    }
                    break;
                case ProfilePersonalizeSteps.skinType:
                    if (this.skinType) {
                        continue;
                    }
                    break;
                case ProfilePersonalizeSteps.consent:
                    if (this.pushNotifications != null) {
                        continue;
                    }
                    break;
            }
            return ProfilePersonalizeSteps[_step];
        }

        return ProfilePersonalizeSteps.drumRoll;
    }

    /* eslint-enable @typescript-eslint/member-ordering */

    //endregion

    public getSkinColor(skinType: SkinType = this.skinType): string | null
    {
        switch (skinType) {
            case SkinType.PALE_WHITE:
                return '#FBE8D7';
            case SkinType.FAIR_WHITE:
                return '#FDD9B8';
            case SkinType.DARKER_WHITE:
                return '#F7BA83';
            case SkinType.LIGHT_BROWN:
                return '#E7A66C';
            case SkinType.BROWN:
                return '#C88243';
            case SkinType.DARK_BROWN:
                return '#954700';
            default:
                return null;
        }
    }

    public async follow(): Promise<void>
    {
        const profileFollow = await lastValueFrom(AppInjector.get(ProfileFollowService).load(this.username));
        await lastValueFrom(AppInjector.get(ProfileFollowService).follow(profileFollow));
    }

    public async unfollow(): Promise<void>
    {
        const profileFollow = await lastValueFrom(AppInjector.get(ProfileFollowService).load(this.username));
        await lastValueFrom(AppInjector.get(ProfileFollowService).unfollow(profileFollow));
    }

    public updateFromJson(data: IProfile & { verifiedDate: Date }): void
    {
        this.setFromJson(data, [
            'bgImageId',
            'businessProfile',
            'change',
            'created',
            'currentOffersConsent',
            'dateOfBirth',
            'displayName',
            'description',
            'earlyAccessConsent',
            'gender',
            'imageId',
            'interests',
            'latestNewsConsent',
            'nickname',
            'skinType',
            'skipNotifications',
            'username',
            'verified',
            'verifiedDate',
            'website',
        ], undefined, {
            dateOfBirth      : (value: string) => value ? new Date(value) : value,
            verifiedDate     : () => data.verified ? new Date(data.verified) : data.verified,
            skipNotifications: (value: Nullable<Array<string>>) => value ? value : [],
        });
        this._imageUrl = undefined;
    }

    public toJson(): IProfile
    {
        return this.toJsonWithKeys([
            'currentOffersConsent',
            'dateOfBirth',
            'description',
            'displayName',
            'earlyAccessConsent',
            'gender',
            'latestNewsConsent',
            'nickname',
            'skinType',
            'website',
        ], {
            dateOfBirth: () => this.dateOfBirth ? moment(this.dateOfBirth).format('YYYY-MM-DD') : undefined,
        });
    }

    private calculateProgressDetail(): Map<ProfilePersonalizeSteps, number>
    {
        const progress = new Map<ProfilePersonalizeSteps, number>();

        progress.set(
            ProfilePersonalizeSteps.name,
            this.displayName?.length ? 100 : 0,
        );

        progress.set(
            ProfilePersonalizeSteps.interests,
            this.interests?.length ? 100 : 0,
        );

        progress.set(
            ProfilePersonalizeSteps.gender,
            this.gender ? 100 : 0,
        );

        progress.set(
            ProfilePersonalizeSteps.birthdate,
            this.dateOfBirth ? 100 : 0,
        );

        progress.set(
            ProfilePersonalizeSteps.skinType,
            this.skinType ? 100 : 0,
        );

        progress.set(
            ProfilePersonalizeSteps.consent,
            this.pushNotifications != null ? 100 : 0,
        );

        return progress;
    }
}
