import { Entity } from '@yukawa/chain-base-angular-client';
import { ChainKey, Change, Image as IImage, ImageBody, ImageSize, Info } from '@yukawa/chain-base-angular-domain';


export interface DownloadParams
{
    category?: string;
    imageId?: number;
    position?: number;
    relatedEntity?: string;
    relatedId?: string;
    relatedModule?: string;
    size?: ImageSize;
    timestamp?: number;
}

export class Image extends Entity<IImage> implements IImage
{
    public category: string            = null as never;
    public image: ImageBody            = null as never;
    public imageId: number             = null as never;
    public info: Info                  = null as never;
    public mime: string                = null as never;
    public position: number            = null as never;
    public related: Required<ChainKey> = null as never;
    public thumbnailLarge: ImageBody   = null as never;
    public thumbnailMedium: ImageBody  = null as never;
    public thumbnailSmall: ImageBody   = null as never;
    public change: Change              = null as never;
    public created: Change             = null as never;

    constructor(initialData: IImage)
    {
        super();

        this.updateFromJson(initialData);
    }

    public get key(): string
    {
        return String(this.imageId);
    }

    public toJson(): IImage
    {
        return this.toJsonWithKeys([
            'category',
            'imageId',
            'info',
            'related',
        ]);
    }

    public updateFromJson(data: IImage): void
    {
        this.setFromJson(data, [
            'category',
            'image',
            'imageId',
            'info',
            'mime',
            'position',
            'related',
            'thumbnailLarge',
            'thumbnailMedium',
            'thumbnailSmall',
            'change',
            'created',
        ]);
    }

    public toUrl(serviceUrl: string, params?: DownloadParams): URL
    {
        const url  = new URL(serviceUrl);
        url.search = new URLSearchParams({
            ...params,
            relatedEntity: this.related.entity,
            relatedId    : this.related.id,
            relatedModule: this.related.module,

        } as DownloadParams as Record<string, string>).toString();

        return url;
    }
}
