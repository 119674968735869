import {Change, ChangeFilter, Created, EntityFilter} from '@yukawa/chain-base-angular-domain';
import {Comment, Content} from './content';
import {PublicProfile} from './profile';


/**
*	@generator rest2ts
*	Represents the notification for some target user <br/>
*	javaType: inc.yukawa.swan.base.core.domain.notification.Notification <br/>
*/
export interface Notification extends  Created{
created?: Change;

/**
*	@example {}
*/
details?: {[key: string]: Object};

/**
*	@example 1
*/
notificationId?: number;
sourceComment?: Comment;
sourceCommentId?: number;
sourceContent?: Content;
sourceContentId?: number;
sourceUser?: PublicProfile;

/**
*	@example user1@example.com
*/
sourceUsername?: string;

/**
*	values: [FOLLOW, UNFOLLOW, CONTENT_PUBLISHED, COMMENT_ADDED, CONTENT_LIKED, MENTIONED_IN_COMMENT, RESPONSE_TO_COMMENT, CONTENT_COMMENT_LIKED,LIVE_STREAM_SCHEDULED, LIVE_STREAM_RESCHEDULED, LIVE_STREAM_STARTS_SOON, LIVE_STREAM_START]<br/>
*	@example FOLLOW
*/
type?: string;

/**
*	@example user1@example.com
*/
username?: string;
}


/**
*	@generator rest2ts
*	Notification filter <br/>
*	javaType: inc.yukawa.swan.base.core.domain.notification.NotificationFilter <br/>
*/
export interface NotificationFilter extends  EntityFilter{
created?: ChangeFilter;

/**
*	@example 1
*/
notificationId?: number;

/**
*	@example ["FOLLOW"]
*/
types?: string[];
unreadOnly?: boolean;

/**
*	@example user1@example.com
*/
username?: string;
}


/**
*	@generator rest2ts
*	Represents the notification for some target user <br/>
*	javaType: inc.yukawa.swan.base.core.domain.notification.NotificationReadMark <br/>
*/
export interface NotificationReadMark {
readMark?: string;
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.notification.NotificationReadMarkFilter <br/>
*/
export interface NotificationReadMarkFilter extends  EntityFilter{

/**
*	@example user1@example.com
*/
username?: string;
}

export enum NotificationType {
FOLLOW = "FOLLOW",
UNFOLLOW = "UNFOLLOW",
CONTENT_PUBLISHED = "CONTENT_PUBLISHED",
COMMENT_ADDED = "COMMENT_ADDED",
CONTENT_LIKED = "CONTENT_LIKED",
MENTIONED_IN_COMMENT = "MENTIONED_IN_COMMENT",
RESPONSE_TO_COMMENT = "RESPONSE_TO_COMMENT",
CONTENT_COMMENT_LIKED = "CONTENT_COMMENT_LIKED",
LIVE_STREAM_SCHEDULED = "LIVE_STREAM_SCHEDULED",
LIVE_STREAM_RESCHEDULED = "LIVE_STREAM_RESCHEDULED",
LIVE_STREAM_STARTS_SOON = "LIVE_STREAM_STARTS_SOON",
LIVE_STREAM_START = "LIVE_STREAM_START",
PROFILE_LEVEL_REACHED = "PROFILE_LEVEL_REACHED",
AWARD_GAINED = "AWARD_GAINED",
ACHIEVEMENT_GAINED = "ACHIEVEMENT_GAINED"
}

