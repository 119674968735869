import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Translation, TranslocoService } from '@ngneat/transloco';


export interface ConfirmDialogData
{
    title: string;
    confirmMessage: string;
    yesButton: string;
    noButton: string;
}

@Component({
    selector   : 'swan-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls  : ['./confirm-dialog.component.scss'],
})
export class SwanConfirmDialogComponent
{
    public title: string;
    public confirmMessage: string;
    public yesButton: string;
    public noButton: string;

    constructor(
        public dialogRef: MatDialogRef<SwanConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
        private readonly _translocoService: TranslocoService,
    )
    {
        this.title          = this.translate(data.title);
        this.confirmMessage = this.translate(data.confirmMessage);
        this.yesButton      = this.translate(data.yesButton);
        this.noButton       = this.translate(data.noButton);
    }

    private translate(value: string): string
    {
        const translation = this._translocoService.getTranslation()
            .get(this._translocoService.getActiveLang()) as Translation;

        if (translation.hasOwnProperty(value)) {
            value = this._translocoService.translate(value);
        }

        return value;
    }

}
