import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Product } from '@swan/lib/domain';
import { ProductService } from '@swan/lib/product';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductResolver implements Resolve<Product | null> {
  private _configService: ConfigService = inject(ConfigService);
  private _client: HttpClient = inject(HttpClient);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Product|null> {
    if(!route.paramMap.get('productId')){
      return of (null)
    }

    return this._client.get<Product>(this._configService.formatUrl('openProductUrl')+"/",{
      params: {
        key: route.paramMap.get('productId')||''
      }
    });
  }
}
