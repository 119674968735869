import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, removeEmpty, RestAspect } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Group, GroupFilter } from '@yukawa/chain-main-angular-core';
import { SessionService, SessionStoreService } from '@yukawa/chain-main-angular-session';
import { lastValueFrom, Observable } from 'rxjs';
import { PlainObject } from 'simplytyped';


@Injectable({ providedIn: 'root' })
export class GroupService extends RestAspect
{
    readonly _groups = new Array<Group>();

    constructor(
        http: HttpClient,
        configService: ConfigService,
        private readonly _sessionService: SessionService,
        private readonly _sessionStoreService: SessionStoreService,
    )
    {
        super(http, configService, configService.formatUrl('groupsUrl'));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get groups(): Array<Group>
    {
        return this._groups;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    insertGroup(group: Group): Observable<Group>
    {
        return this.http.post<any>(this.formatServiceUrl(), group);
    }

    editGroup(group: Group): Observable<Group>
    {
        return this.http.put<any>(this.formatServiceUrl(), group);
    }

    loadGroup(groupName: string): Observable<Group>
    {
        return this.http.get<any>(this.formatServiceUrl(`/${groupName}`));
    }

    deleteGroup(groupName: string): Observable<Group>
    {
        return this.http.delete<any>(this.formatServiceUrl(`/${groupName}`));
    }

    findGroup(firstResult?: number, pageSize?: number): Observable<Array<Group>>
    {
        const options = removeEmpty<PlainObject>({
            firstResult,
            pageSize,
        });
        let path      = '/find';
        if (Object.keys(options).length) {
            path = `${path}?${new URLSearchParams(options).toString()}`;
        }
        return this.http.get<any>(this.formatServiceUrl(path));
    }

    queryGroup(filter: GroupFilter): Observable<QueryResult<Group>>
    {
        return this.query<any>(this.formatServiceUrl('/query'), filter);
    }

    async loadGroups(): Promise<void>
    {
        const groups        = await lastValueFrom(this.findGroup());
        this._groups.length = 0;
        this._groups.push(...groups);
    }
}
