import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContentFilter } from '@swan/lib/domain';
import { CachedRepoEntityService, ConfigService } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Observable } from 'rxjs';
import { PlainObject } from 'simplytyped';
import { ContentVideoFile } from './content-video-file.model';
import { ContentView } from './content.model';


@Injectable()
export class ContentViewService extends CachedRepoEntityService<ContentVideoFile, ContentFilter, number>
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, 'contentUrl', entity => entity.id);
    }

    queryEnriched(filter: ContentFilter): Observable<QueryResult<ContentVideoFile>>
    {
        return this.repository.add(this.http.post(this.formatServiceUrl('/queryEnriched'), filter));
    }

    loadEnriched(contentId: number): Observable<ContentVideoFile>
    {
        return this.repository.add(this.http.get(this.formatServiceUrl('/enriched') + '?key=' + contentId));
    }

    protected createInstanceFrom(json: ContentView, other: PlainObject | undefined): ContentVideoFile
    {
        return new ContentVideoFile(json);
    }
}
