import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PlaybackToken } from '@swan/lib/content';
import { Content } from '@swan/lib/content';
import { ConfigService } from '@yukawa/chain-base-angular-client';


@Injectable({
  providedIn: 'root'
})
export class OpenContentService {


  constructor(private _httpClient: HttpClient, private _configService: ConfigService) { }

  getPlaybackToken(contentId: string) {
    return this._httpClient.get<PlaybackToken>(this._configService.formatUrl('openContentUrl')+"/playback", {
      params: {
        key: contentId
      }
    });
  }

  getContentData(contentId: string) {
    return this._httpClient.get<Content>(this._configService.formatUrl('openContentUrl')+"/", {
      params: {
        key: contentId
      }
    });
  }

}
