import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Asset, Upload } from '@mux/mux-node';
import { Content } from '@swan/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { map, Observable } from 'rxjs';


@Injectable()
export class AdminMuxService extends RestAspect
{
    public constructor(
        _configService: ConfigService,
        http: HttpClient,
    )
    {
        super(http, _configService, _configService.formatUrl('muxUrl'));
    }

    public getAssets(limit: number, page: number): Observable<Array<Asset>>
    {
        return this.http.get<Array<{ data: Array<Asset> }>>(this.formatServiceUrl() + `/assets?limit=${limit}&${page}`).pipe(
            map(response => response.reduce((previous, current) =>
                {
                    previous.push(...current.data);
                    return previous;
                }, new Array<Asset>()),
            ));
    }

    public getAssetById(asssetId: string): Observable<Asset>
    {
        return this.http.get<{ data: Asset }>(this.formatServiceUrl(`/assets/${asssetId}`)).pipe(
            map(response => response.data),
        );
    }

    public getUploads(limit: number, page: number): Observable<Array<Upload>>
    {
        return this.http.get<Array<{ data: Array<Upload> }>>(this.formatServiceUrl() + `/uploads?limit=${limit}&${page}`).pipe(
            map(response => response.reduce((previous, current) =>
                {
                    previous.push(...current.data);
                    return previous;
                }, new Array<Upload>()),
            ));
    }

    public postUpload(content: Content): Observable<Upload>
    {
        return this.http.post<{ data: Upload }>(this.formatServiceUrl('/uploads/'), content).pipe(
            map(response => response.data),
        );
    }

    public getUploadById(uploadId: string): Observable<Upload>
    {
        return this.http.get<{ data: Upload }>(this.formatServiceUrl(`/uploads/${uploadId}`)).pipe(
            map(response => response.data),
        );
    }
}
