import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { SubtitleTrack } from "./content.model";
import { BehaviorSubject, Observable, finalize, tap } from "rxjs";
import { ContentService } from "./content.service";

export class SubtitleTrackDataSource implements DataSource<SubtitleTrack>{

    subtitleTracksSubject = new BehaviorSubject<readonly SubtitleTrack[]>([]);
    private _loading = new BehaviorSubject<boolean>(false);
    contentId:string;

    constructor(private _contentService: ContentService, contentId:string){
        this.contentId = contentId;
    }

    public get loading(): Observable<boolean>{return this._loading.asObservable();}

    connect(collectionViewer: CollectionViewer): Observable<readonly SubtitleTrack[]> {
        return this.subtitleTracksSubject.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
        this.subtitleTracksSubject.complete();
        this._loading.complete();
    }

    loadTracks(){
        this._loading.next(true);
        this._contentService.getSubtitleTracks(this.contentId).pipe(
            finalize(() => this._loading.next(false))
        ).subscribe(
            (tracks) => {
                this.subtitleTracksSubject.next(tracks)
            });
    }
    reload(){
        this.loadTracks();
    }
    deleteTrack(track: SubtitleTrack){
        return this._contentService.deleteSubtitleTrack(String(this.contentId), track.trackId)
          .pipe(
            finalize(() => {
                this.subtitleTracksSubject.next(this.subtitleTracksSubject.value.filter(t => t.trackId !== track.trackId));
                this.reload();        
              })
          );
    }
    addTrack(track:{language:string, file:File, name:string}){
        return this._contentService.uploadSubtitleTrack(this.contentId, track.language, track.file, track.name).pipe(
            finalize(() => {
                console.log('added Track');
                this.reload()})
        )
    }


}
