import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppDialog } from '../dialog/appDialog.component';
import { ActivatedRoute } from '@angular/router';

export function getStoreLink():string{
  var userAgent = navigator.userAgent;

  if (userAgent.includes("Android")) {
      return "https://play.google.com/store/apps/details?id=com.swanmirror.android";
  } else if (userAgent.includes("iPhone")) {
      return "https://apps.apple.com/de/app/swan-beauty/id1641229945";
  }
  return "https://www.swanmirror.com/de#swan-app"
}

export function openApp() {
  const hostname = window.location.hostname;
  
    let schemaPrefix = 'swan:/';
    if (hostname.includes('.qa.')) {
      schemaPrefix = 'swan-qa:/';
    } else if (hostname.includes('.dev.')) {
      schemaPrefix = 'swan-dev:/';
    }

    const schemaLink = window.location.href.replace(window.location.origin, schemaPrefix);
    window.location.href = schemaLink;

    setTimeout(() => {
      window.location.href = getStoreLink();
    }, 3000);
  }


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(
    private _dialog: MatDialog,
    private _activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // TODO [AM] mp - disabled dialog cleanup
    // if(this._activeRoute.snapshot.data['dialog'] === undefined || this._activeRoute.snapshot.data['dialog'] === true){
    //   this._dialog.open(AppDialog, {data: this.getStoreLink()});
    // }
  }

  openApp = openApp;
}
