import { TranslocoService } from '@ngneat/transloco';
import { InterestInfo, InterestInfoService, Profile } from '@swan/lib/profile';
import { AppInjector, timeFormat } from '@yukawa/chain-base-angular-client';
import { Info } from '@yukawa/chain-base-angular-domain/chain/base/core/info';
import { Content, ContentView } from './content.model';
import { IVideoFile, VideoFile } from './video-list.model';


export class ContentVideoFile extends VideoFile<Content, ContentView>
{
    public readonly id: number                     = null as never;
    public readonly fileId: string                 = null as never;
    public readonly info: Required<Info>           = null as never;
    public readonly author: Profile                = null as never;
    public readonly duration: string               = null as never;
    public readonly interests: Array<InterestInfo> = null as never;
    public readonly fileInfo: Content              = null as never;

    public favorite: boolean = false;
    public liked: boolean    = false;
    public selected: boolean = false;

    public constructor(
        fileInfo: ContentView,
    )
    {
        super();

        this.updateFromJson(fileInfo);
    }

    //region Entity Members

    public get key(): string
    {
        return this.id.toString();
    }

    public toJson(): Content
    {
        return this.toJsonWithKeys([
            'contentId',
        ]);
    }

    public updateFromJson(data: ContentView): void
    {
        const userName  = data.content.author?.username ?? data.content.change.user;
        let displayName = data.content.author?.displayName ?? data.content.change.user;
        switch (userName) {
            case 'init':
            case 'admin':
                displayName = 'SWAN';
        }

        const author = !data.content.author
            ? {
                username: data.content.change.user,
                displayName,
            } as Profile
            : {
                ...data.content.author,
                displayName,
            };

        this.setFromJson<IVideoFile<Content> & { interests: Array<InterestInfo> }>(this, [
            'id',
            'fileId',
            'info',
            'author',
            'duration',
            'interests',
            'fileInfo',
            'favorite',
            'liked',
        ], undefined, {
            id       : data.content.contentId,
            fileId   : data.content.extAssetId,
            info     : data.content.info,
            author   : new Profile(author),
            duration : () =>
            {
                let suffix: string;
                let duration: string;
                if ((data.content.duration ?? 0) <= 59) {
                    suffix   = 'VIDEO.LENGTH.SECONDS';
                    duration = timeFormat(data.content.duration, true);
                }
                else {
                    suffix   = 'VIDEO.LENGTH.MINUTES';
                    duration = timeFormat(data.content.duration, true);
                }
                suffix = AppInjector.get(TranslocoService).translate(suffix);
                return `${duration} ${suffix}`;
            },
            interests: () => data.content.interests
                ? data.content.interests.map(interest => AppInjector.get(InterestInfoService)
                    .findInterest(interest?.interestId))
                : [],
            fileInfo : data.content,
            favorite : !!data.favorite,
            liked    : !!data.liked,
        });
    }

    //endregion
}
