import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArgumentNullException } from '@awesome-nodes/object';
import { ArgumentException, CachedRepoEntityService, ConfigService } from '@yukawa/chain-base-angular-client';
import { ImageFilter } from '@yukawa/chain-base-angular-domain';
import moment from 'moment';
import { Observable, switchMap } from 'rxjs';
import { PlainObject } from 'simplytyped';
import { DownloadParams, Image } from './image.entity';


@Injectable()
export class ImageService extends CachedRepoEntityService<Image, ImageFilter, number>
{
    constructor(
        http: HttpClient,
        configService: ConfigService)
    {
        super(
            http,
            configService,
            configService.config?.mediaAdminUrl ? 'mediaAdminUrl' : 'mediaUrl',
            (entity: Image) => entity.imageId,
        );
    }

    /**
     * Read the given file for demonstration purposes
     *
     * @param file
     */
    readAsDataURL(file: File): Promise<string>
    {
        // Return a new promise
        return new Promise((resolve, reject) =>
        {

            // Create a new reader
            const reader = new FileReader();

            // Resolve the promise on success
            reader.onload = (): void =>
            {
                resolve(reader.result as string);
            };

            // Reject the promise on error
            reader.onerror = (e): void =>
            {
                reject(e);
            };

            // Read the file as the
            reader.readAsDataURL(file);
        });
    }

    /**
     * Add new File
     */
    upload(image: Image, file?: File): Observable<Image>
    {
        const formData = new FormData();
        if (file) {
            formData.append('file', file, file.name);
        }

        const json = JSON.stringify(image.toJson());
        const blob = new Blob([json], {
            type: 'application/json',
        });
        formData.append('image', blob);

        if (file) {
            const uploadObservable: Observable<Image> = this.repository.add(this.http.post<Image>(
                this.formatServiceUrl('/upload'),
                formData,
            ), image);

            return image.imageId
                ? this.delete(image).pipe(switchMap(() => uploadObservable))
                : uploadObservable;
        }
        else {
            return this.repository.update(this.http.post<Image>(this.formatServiceUrl('/merge'), formData), image);
        }

    }

    public uploadImage(image: Image, file: File): Observable<Image>
    {
        if (!file) {
            throw new ArgumentNullException('', 'file');
        }

        // Return if the file is not allowed
        const allowedTypes = ['image/jpeg', 'image/png'];
        if (!allowedTypes.includes(file.type)) {
            throw new ArgumentException(`Invalid mime type '${file.type}'.`);
        }

        if (image.imageId) {
            this.repository.remove(image.imageId);
        }

        const formData = new FormData();
        formData.append('file', file, file.name);

        return this.repository.update(this.http.post<Image>(this.uploadUrl(image), formData), image);
    }

    public uploadUrl(image: Image): string
    {
        return image.toUrl(`${this.formatServiceUrl()}/uploadMainImageByRelated`).toString();
    }

    public uploadFile<T>(endpoint: string, file: File): Observable<T>
    {
        if (!endpoint) {
            throw new ArgumentNullException('', 'endpoint');
        }

        if (!file) {
            throw new ArgumentNullException('', 'file');
        }

        // Return if the file is not allowed
        const allowedTypes = ['image/jpeg', 'image/png'];
        if (!allowedTypes.includes(file.type)) {
            throw new ArgumentException(`Invalid mime type '${file.type}'.`);
        }

        const formData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post<T>(endpoint, formData);
    }

    /**
     * Returns a browser cache resistant download url for the specified image.
     *
     * @param image
     * @param params
     */
    public downloadUrl(image: Image, params?: DownloadParams): string
    public downloadUrl(params?: DownloadParams): string
    public downloadUrl(image: Image | DownloadParams, params?: DownloadParams): string
    {
        const serviceUrl = `${this.configService.getValue('baseUrl')}${this.configService.getValue('mediaUrl')}/download`;

        let downloadUrl: string;

        if (image instanceof Image) {
            downloadUrl = image.toUrl(serviceUrl, {
                ...params as DownloadParams,
                timestamp: moment(image.change.date).unix(),
            }).toString();
        }
        else {
            const url  = new URL(serviceUrl);
            url.search = new URLSearchParams({
                ...image,
            } as DownloadParams as Record<string, string>).toString();

            downloadUrl = url.toString();
        }
        return downloadUrl;
    }

    protected createInstanceFrom(json: PlainObject, other?: PlainObject): Image
    {
        return new Image(json);
    }

    protected override keyForEntityCache(json: Image): string
    {
        return json.key;
    }
}
