import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FavoriteContent } from '@swan/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { Change, QueryResult } from '@yukawa/chain-base-angular-domain';
import { TableFilter } from '@yukawa/chain-base-angular-domain/chain/base/core/filter';
import { Observable } from 'rxjs';
import { Content } from './content.model';


export interface Favorite extends FavoriteContent
{
    change: Change;
    contentId: number;
    username: string;
}

@Injectable()
export class FavoriteService<T extends Content = Content> extends RestAspect
{
    active: boolean = false;

    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('favoriteUrl'));
    }

    add(file: T): Observable<Favorite>
    {
        return this.http.post<Favorite>(this.formatServiceUrl('/add'), file.contentId);
    }

    remove(file: T): Observable<Favorite>
    {
        return this.http.post<Favorite>(this.formatServiceUrl('/remove'), file.contentId);
    }

    override query<E = Favorite>(filter: string | TableFilter): Observable<QueryResult<E>>
    {
        return this.http.post<QueryResult<E>>(this.formatServiceUrl('/query'), filter);
    }
}
