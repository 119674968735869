import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentResolver } from './comp/content/content.resolver';
import { ContentPlayerComponent } from './comp/content/content-player/content-player.component';
import { LayoutComponent } from './comp/shared/layout/layout.component';
import { ProfileResolver } from './comp/profile/profile.resolver';
import { ProductResolver } from './comp/product/product.resolver';
import { ProductViewComponent } from './comp/product/view/view.component';
import { ProfileComponent } from './comp/profile/profile.component';
import { NotFoundComponent } from './comp/shared/not-found/not-found.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '404' },
  {
    path: '',
    component: LayoutComponent,
    children:[
      {
        path: "share/content/:contentId",
        component: ContentPlayerComponent,
        resolve: {
          data: ContentResolver
        }, 
      },
      {
        path: "share/profile/:nickname",
        component: ProfileComponent, 
        resolve: {
          profile: ProfileResolver
        }
      },
      {
        path: "share/product/:productId",
        component: ProductViewComponent, 
        resolve: {
          product: ProductResolver
        },
      }
    ]
  },
  {
    path:'',
    component: LayoutComponent,
    data: {dialog: false},
    children:[
      {
        path: "404",
        component: NotFoundComponent,
      },
    ]
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
