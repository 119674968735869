<div class="header m-6 flex gap-4 ">
    <div class="profile-img relative">
        <img *ngIf="profile.imageId; else swanLogo" [src]="getImageUrl()" alt="SWAN Logo"
            style="border-radius: 50%; width: 120px; height: auto; margin: 6px;">
        <ng-template #swanLogo>
            <img src="/assets/icons/swan-icon.svg" alt="SWAN Logo"
                style="border-radius: 50%; width: 120px; height: auto; margin: 6px;">
        </ng-template>
        <div class="absolute w-full h-full rounded-full top-0 border-4  " [ngStyle]="{'border-color':levelColor()}">
        </div>
    </div>
    <div class="header-text ml-2 flex flex-col justify-center">
        <h1 class="flex items-center gap-2">{{profile.displayName}} <mat-icon *ngIf="profile.verified"
                svgIcon="swan:verified" class="min-w-0 min-h-0"></mat-icon>
        </h1>
        <span class="nick hidden">{{'@'+profile.nickname}}</span>
        <div>
            <span [ngStyle]="{'color': levelColor()}" *ngIf="profile.level" class="level-label">Level {{profile.level}}
                {{"\u2022"}} </span>
            <span class="text-gray-500">{{profile.uploadsCount}} Videos</span>
        </div>
    </div>
</div>
<div class="ml-6 mr-6">
    <p>{{ (profile.description|| '') | emoji}}</p>
    <a [href]="getStoreLink()" mat-stroked-button class="flex mt-4 w-full">{{ "FOLLOW" | transloco}}</a>
</div>
<div class="absolute bottom-0 flex flex-col items-center w-full p-6">
    <p>{{"SHARED_PROFILE_CTA" | transloco}}</p>
    <a [href]="getStoreLink()" class="block w-full" mat-flat-button class="bg-black text-white rounded-full mt-6">
        {{ "Download App" | transloco}}
    </a>
</div>