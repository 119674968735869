import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import {
    Translation,
    TRANSLOCO_CONFIG,
    TRANSLOCO_LOADER,
    translocoConfig,
    TranslocoConfig,
    TranslocoModule,
    TranslocoService,
} from '@ngneat/transloco';
import { lastValueFrom } from 'rxjs';
import { TranslocoHttpLoader } from './transloco.http-loader';


@NgModule({
    exports  : [
        TranslocoModule,
    ],
    providers: [
        {
            // Provide the default Transloco loader
            provide : TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader,
        },
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide   : APP_INITIALIZER,
            deps      : [TranslocoService],
            useFactory: (translocoService: TranslocoService): any => (): Promise<Translation> =>
            {
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);
                return lastValueFrom(translocoService.load(defaultLang));
            },
            multi     : true,
        },
    ],
})
export class TranslocoCoreModule
{
    public constructor(
        private readonly _translocoService: TranslocoService,
    )
    {
    }

    static forRoot(config: Partial<TranslocoConfig>): ModuleWithProviders<TranslocoCoreModule>
    {
        return {
            ngModule : TranslocoCoreModule,
            providers: [
                {
                    // Provide the default Transloco configuration
                    provide : TRANSLOCO_CONFIG,
                    useValue: translocoConfig(config),
                },
            ],
        };
    }

    public async mergeTranslations(transformLocation: (lang: string) => string): Promise<void>
    {
        await Promise.all(this._translocoService.getAvailableLangs().map(async (lang) =>
        {
            lang = typeof lang === 'string' ? lang : lang.id;
            this._translocoService.setTranslation(
                await lastValueFrom(this._translocoService.load(transformLocation(lang))),
                lang, {
                    merge: true,
                },
            );
        }));
    }
}
