import { Change, Owner, Person } from '@yukawa/chain-base-angular-domain';
import { User as IUser } from '@yukawa/chain-main-angular-core';
import { Role as YukawaRole } from '@yukawa/chain-main-angular-session';
import { Account, GroupContext } from '@yukawa/chain-security-angular-core';
import { Profile } from './profile.entity';


export interface User extends IUser
{
    account: Account;
    created: Required<Change>;
    change: Required<Change>;
    defaultOrgId: string;
    details: Record<string, object>;
    groupContexts: Required<GroupContext>[];
    owner: Required<Owner>;
    person: Required<Person>;
    userId: string;
    username: string;
    profile: Profile;

    //region UI Properties

    businessProfile: boolean;
    verified: Date;

    //endregion
}

export class Role extends YukawaRole
{

    //region todo: remove the following roles from @yukawa/chain-main-angular-session

    //static readonly profileReader = Role.enum<Role>(0x200, 'ROLE_PROF_R');
    //static readonly contentEdit   = Role.enum<Role>(0x400, 'ROLE_CONT_E');
    //static readonly interestEdit  = Role.enum<Role>(0x800, 'ROLE_INT_E');
    //static readonly switchEnv     = Role.enum<Role>(0x1000, 'ROLE_SWITCH_ENV');

    //endregion

    static readonly userEdit    = Role.enum<Role>(0x2000, 'ROLE_USER_EDIT');
    static readonly productEdit = Role.enum<Role>(0x4000, 'ROLE_PARTNER_PROD_E');
    static readonly adminUi     = Role.enum<Role>(0x8000, 'ROLE_ADM_UI');
}
