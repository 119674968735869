import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Content, LikedContent as ILikedContent, LikedContentFilter } from '@swan/lib/domain';
import { CachedRepoEntityService, ConfigService, Entity } from '@yukawa/chain-base-angular-client';
import { Change } from '@yukawa/chain-base-angular-domain';
import { PlainObject } from 'simplytyped';


export class LikedContent extends Entity<ILikedContent> implements ILikedContent
{
    public readonly change: Change    = null as never;
    public readonly contentId: number = null as never;
    public readonly username: string  = null as never;

    public readonly content: Content;

    constructor(initialData: ILikedContent, content: Content)
    {
        super(); // delay update from json
        this.content = content;
        if (initialData) {
            this.updateFromJson(initialData);
        }
    }

    public get key(): string
    {
        return this.contentId.toString();
    }

    public updateFromJson(data: ILikedContent): void
    {
        this.setFromJson(data, [
            'change',
            'contentId',
            'username',
        ]);
    }

    public toJson(): ILikedContent
    {
        return this.toJsonWithKeys(['contentId']);
    }
}

@Injectable()
export class LikedService extends CachedRepoEntityService<LikedContent, LikedContentFilter, number>
{
    constructor(http: HttpClient, configService: ConfigService)
    {
        super(http, configService, 'likeUrl', entity => entity.contentId);
    }

    protected override keyForEntityCache(json: LikedContent): string
    {
        return json.key;
    }

    protected createInstanceFrom(json: LikedContent, other: PlainObject): LikedContent
    {
        return new LikedContent(json, other);
    }
}

