import { Disposable, Timeout, TimeoutInstance } from '@yukawa/chain-base-angular-client';
import { lastValueFrom } from 'rxjs';
import { ContentVideoFile } from './content-video-file.model';
import { PlayerStatisticsService } from './player-statistics.service';


export class PlayerTickTimer extends Disposable
{
    #secondTimeoutInstance: TimeoutInstance = null as never;
    #tickTimeoutInstance: TimeoutInstance   = null as never;

    private _secondsViewed = new Map<number, boolean>();

    public constructor(
        private readonly _playerStatisticsService: PlayerStatisticsService,
        private readonly _player: { duration: number; currentTime: number },
        private readonly _videoFile: ContentVideoFile,
        private readonly _tickTimespan = 30 * 1000)
    {
        super();

        for (let i = 1; i <= (_videoFile.fileInfo.duration as number); i++) {
            this._secondsViewed.set(i, false);
        }
    }

    public get running(): boolean
    {
        return this.#tickTimeoutInstance?.exists() && !this.#tickTimeoutInstance.paused();
    }

    public get totalSecondsViewed(): number
    {
        return Array.from(this._secondsViewed.keys())
            .reduce((previous, current) => previous + +!!this._secondsViewed.get(current), 0);
    }

    public override dispose(disposing?: boolean): void
    {
        if (disposing) {
            this.stop();
        }
        super.dispose(disposing);
    }

    public start(): void
    {
        this.startTimer();
    }

    public pause(): void
    {
        this.pauseTimer();
    }

    public async stop(tick: boolean = false): Promise<void>
    {
        if (!this.#tickTimeoutInstance?.exists()) {
            return;
        }
        this.stopTimer();
        if (tick) {
            await this.tick();
        }
    }

    protected startTimer(): void
    {
        if (this.#tickTimeoutInstance?.paused()) {
            this.resumeTimer();
            return;
        }
        else {
            this.stopTimer();
        }
        this.#tickTimeoutInstance   = Timeout.instantiate(this.intervalTick.bind(this), this._tickTimespan);
        this.#secondTimeoutInstance = Timeout.instantiate(this.secondTick.bind(this), 1000);
    }

    protected pauseTimer(): void
    {
        this.#tickTimeoutInstance?.pause();
        this.#secondTimeoutInstance?.pause();
    }

    protected resumeTimer(): void
    {
        this.#tickTimeoutInstance?.resume();
        this.#secondTimeoutInstance?.resume();
    }

    protected stopTimer(): void
    {
        this.#tickTimeoutInstance?.clear(true);
        this.#secondTimeoutInstance?.clear(true);
    }

    protected secondTick(): void
    {
        this.#secondTimeoutInstance.set(this.secondTick.bind(this), 1000);
        this._secondsViewed.set(Math.round(this._player.currentTime), true);
    }

    protected intervalTick(): void
    {
        this.#tickTimeoutInstance.set(this.intervalTick.bind(this), this._tickTimespan);
        this.tick();
    }

    private tick(): Promise<void>
    {
        return lastValueFrom(this._playerStatisticsService.tick({
            contentId         : this._videoFile.id,
            duration          : this._player.duration,
            second            : this._player.currentTime,
            totalSecondsViewed: this.totalSecondsViewed,
        }));
    }
}
