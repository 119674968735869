<div class="bg-white h-full">
    <div *ngIf="profile.businessProfile; else privateProfileHeader">
        <div class="relative business-header">
            <img *ngIf="profile.bgImageId; else placeholder" [src]="getImageUrl(profile.bgImageId)" alt="" height="">
            <ng-template #placeholder>
                <div class="min-h-[250px] bg-black flex">
                    <mat-icon svgIcon="swan:logo" class="m-auto w-24 text-white"></mat-icon>
                </div>
            </ng-template>
            <div class="profile-img">
                <img *ngIf="profile.imageId; else swanLogo" [src]="getImageUrl(profile.imageId)" alt="SWAN Logo"
                    style="border-radius: 50%; width: 120px; height: auto; margin: 3px;">
                <ng-template #swanLogo>
                    <img src="/assets/icons/swan-icon.svg" alt="SWAN Logo"
                        style="border-radius: 50%; width: 120px; height: auto; margin: 6px;">
                </ng-template>
                <div class="absolute w-full h-full rounded-full top-0 border-4  "
                    [ngStyle]="{'border-color':levelColor()}">
                </div>
            </div>
        </div>
        <div class="ml-8 mr-8 pt-4">
            <div class="header-text flex flex-col justify-center">
                <h1 class="flex items-center gap-2">{{profile.displayName}} <mat-icon *ngIf="profile.verified"
                        svgIcon="swan:verified" class="min-w-0 min-h-0"></mat-icon>
                </h1>
                <span class="nick hidden">{{'@'+profile.nickname}}</span>
                <div>
                    <span [ngStyle]="{'color': levelColor()}" *ngIf="profile.level" class="level-label">Level
                        {{profile.level}}
                        {{"\u2022"}} </span>
                    <span class="text-gray-500">{{profile.uploadsCount}} Videos</span>
                </div>
            </div>
        </div>
    </div>
    <ng-template #privateProfileHeader>
        <div class="header ml-8 mr-8 pt-8 mb-8 flex gap-4">
            <div class="profile-img relative flex-shrink-0">
                <img *ngIf="profile.imageId; else swanLogo" [src]="getImageUrl(profile.imageId)" alt="SWAN Logo"
                    style="border-radius: 50%; width: 120px; height: auto; margin: 6px;">
                <ng-template #swanLogo>
                    <img src="/assets/icons/swan-icon.svg" alt="SWAN Logo"
                        style="border-radius: 50%; width: 120px; height: auto; margin: 6px;">
                </ng-template>
                <div class="absolute w-full h-full rounded-full top-0 border-4  "
                    [ngStyle]="{'border-color':levelColor()}">
                </div>
            </div>
            <div class="header-text ml-2 flex flex-col justify-center">
                <h1 class="flex items-center gap-2">{{profile.displayName}} <mat-icon *ngIf="profile.verified"
                        svgIcon="swan:verified" class="min-w-0 min-h-0"></mat-icon>
                </h1>
                <span class="nick hidden">{{'@'+profile.nickname}}</span>
                <div>
                    <span *ngIf="profile.businessProfile" class="text-gray-500"> {{"Beauty Brand" | transloco }}
                        {{"\u2022"}} </span>
                    <span [ngStyle]="{'color': levelColor()}" *ngIf="profile.level" class="level-label">Level
                        {{profile.level}}
                        {{"\u2022"}} </span>
                    <span class="text-gray-500">{{profile.uploadsCount}} Videos</span>
                </div>
            </div>
        </div>
    </ng-template>
    <div class="ml-8 mr-8">
        <p>{{ (profile.description|| '') | emoji }}</p>
        <div class="flex mt-4 gap-2 text-black">
            <a *ngIf="!profile.businessProfile; else blackButton" (click)="getStoreLink()" mat-stroked-button
                class="flex w-full">{{ "FOLLOW" |
                transloco}}</a>
            <ng-template #blackButton>
                <a (click)="getStoreLink()" mat-flat-button class=" bg-black text-white flex flex-grow-1 basis-2/3">{{
                    "FOLLOW" |
                    transloco}}</a>
            </ng-template>
            <a mat-stroked-button *ngIf="profile.businessProfile"><mat-icon svgIcon="mat_outline:mail"
                    class="text-black"></mat-icon></a>
            <a mat-stroked-button *ngIf="profile.businessProfile"><mat-icon svgIcon="mat_outline:language"
                    class="text-black"></mat-icon></a>
        </div>
    </div>
    <mat-tab-group mat-stretch-tabs="true" animationDuration="0ms" *ngIf="false && profile.businessProfile" class="mt-6 mb-6">
        <mat-tab label="Videos"></mat-tab>
        <mat-tab label="Products"></mat-tab>
        <mat-tab label="Live Shows"></mat-tab>
    </mat-tab-group>
    <div *ngIf="profile.businessProfile" class="m-8">

    </div>
    <div class="absolute bottom-0 flex flex-col items-center w-full p-6">
        <p>{{"SHARED_PROFILE_CTA" | transloco}}</p>
        <a (click)="getStoreLink()" class="block w-full" mat-flat-button class="bg-black text-white rounded-full mt-6">
            {{ "Download App" | transloco}}
        </a>
    </div>
</div>