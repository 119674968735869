import { Injector } from '@angular/core';
import { ContentFilter, ContentStatus, ContentStreamType } from '@swan/lib/domain';
import { EntityFilter, QueryResult } from '@yukawa/chain-base-angular-domain';
import { lastValueFrom } from 'rxjs';
import { ContentVideoFile } from './content-video-file.model';
import { ContentViewService } from './content-view.service';
import { Content } from './content.model';
import { ContentService } from './content.service';
import { IVideoFile, VideoList } from './video-list.model';


export class ContentVideoList<
    TFile extends ContentVideoFile = ContentVideoFile,
    TFilter extends EntityFilter = ContentFilter>
    extends VideoList<Content, TFilter, TFile>
{
    public static readonly defaultPager = {
        firstResult: 0,
        pageSize   : 10,
    };

    public static readonly defaultFilter: ContentFilter = {
        orderDir: 'DESC',
        pager   : ContentVideoList.defaultPager,
        statuses: [
            ContentStatus.PUBLISHED,
        ],
        stream  : ContentStreamType.TRENDING,
    };

    public constructor(
        injector: Injector,
        filter: TFilter = ContentVideoList.defaultFilter as TFilter,
    )
    {
        super(injector, filter);
    }

    public async query(stream: ContentStreamType = ContentStreamType.TRENDING): Promise<QueryResult<TFile>>
    {
        return (await lastValueFrom((this.injector.get(ContentViewService)).queryEnriched(this.filter))) as QueryResult<TFile>;
    }

    public override async delete(videoFile: TFile): Promise<void>
    {
        await this.injector.get(ContentService).deleteContent(videoFile.fileInfo);
        this.items.splice(this.items.indexOf(videoFile), 1);
    }

    protected override map(
        fileInfo: IVideoFile<Content>,
        index: number,
        array: Array<IVideoFile<Content>>): TFile
    {
        return (fileInfo instanceof ContentVideoFile
            ? fileInfo
            : new ContentVideoFile({
                content: fileInfo as never,
            })) as TFile;
    }
}
