import { Component, OnInit, Optional, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './appDialog.component.html',
  styleUrls: ['./appDialog.component.scss']
})
export class AppDialog implements OnInit {
  @Optional() dialogRef: MatDialogRef<AppDialog> = inject(MatDialogRef);
  link = inject(MAT_DIALOG_DATA)

  constructor() {}

  ngOnInit(): void {
    setTimeout(()=>{
      this.dialogRef.close();
    }, 3000)
  }
}
