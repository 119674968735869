import { Validators } from '@angular/forms';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { Address, Change, Person } from '@yukawa/chain-base-angular-domain';
import { cloneDeep } from 'lodash-es';
import { Nullable, PlainObject, StringKeys } from 'simplytyped';
import { GroupService } from './group.service';
import { Profile } from './profile.entity';
import { Profile as IProfile } from './profile.model';
import { PublicProfileService } from './public-profile.service';
import { User } from './user.model';


export class UserTableEntry extends QueryTableEntry<User>
{
    static viewConfig: User = {
        userId         : '',
        username       : '',
        account        : {
            details     : {},
            credentials : {
                username: '',
                password: '',
                orgId   : '',
            },
            roleContexts: [
                {
                    orgId: '',
                    roles: [''],
                },
            ],
            roles       : [''],
            status      : {
                accountNonExpired    : true,
                accountNonLocked     : true,
                credentialsNonExpired: true,
                enabled              : true,
            },
        },
        defaultOrgId   : 'swan',
        details        : {},
        groupContexts  : [
            {
                groups: ['USERS'],
                orgId : 'swan',
                userId: '',
            },
        ],
        verified       : new Date(),
        businessProfile: false,
        owner          : {
            groups: [],
            user  : '',
        },
        person         : {
            addresses  : [
                {
                    addressId  : '',
                    city       : '',
                    countryCode: '',
                    houseNumber: '',
                    street     : '',
                    type       : 'main',
                    zipCode    : '',
                },
            ],
            change     : {
                date : new Date(),
                notes: '',
                user : '',
            },
            companyName: '',
            created    : {
                date : new Date(),
                notes: '',
                user : '',
            },
            email      : '',
            firstName  : '',
            lang       : '',
            lastName   : '',
            mobile     : '',
            phoneNumber: '',
            role       : '',
            salutation : '',
            shortName  : '',
            title      : '',
            vatNumber  : '',
        },
        profile: {
            nickname   : '',
            displayName: '',
        } as never,
        created        : {
            date : new Date(),
            notes: '',
            user : '',
        },
        change         : {
            date : new Date(),
            notes: '',
            user : '',
        },
    };

    public constructor(
        user: User = cloneDeep(UserTableEntry.viewConfig),
    )
    {
        super(user, user?.userId, user.username);

        if (user.person?.addresses?.length === 0 && UserTableEntry.viewConfig.person.addresses?.length) {
            user.person.addresses.push(cloneDeep(UserTableEntry.viewConfig.person.addresses[0]));
        }

        if (user.created) {
            user.created.date = new Date(user.created.date);
        }
        if (user.change) {
            user.change.date = new Date(user.change.date);
        }

        if (!user.defaultOrgId) {
            user.defaultOrgId = UserTableEntry.viewConfig.defaultOrgId;
        }
        if (user.profile) {
            user.profile = new Profile(user.profile);
        }
        user.businessProfile = null as never;
        user.verified        = null as never;
    }

    public get viewConfig(): User
    {
        return UserTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'USER.';
    }

    public override init(): void
    {
        super.init();
    }

    protected override mapDetails<TKey = User>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType;
        let value         = item?.[key];
        let options       = new Array<ISelectOption>();
        detail.entityName = 'User';

        switch ((key as StringKeys<User> | StringKeys<Person> | StringKeys<Address>)) {
            case 'account':
            case 'defaultOrgId':
            case 'details':
            case 'owner':
                return;
            case 'change':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case 'created':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case 'groupContexts':
                type              = 'multiselect';
                detail.canEdit    = true;
                detail.required   = true;
                detail.validators = [Validators.minLength(1)];
                if (!value) {
                    value = ['USERS'];
                }
                else if (value?.[0]) {
                    value = value[0].groups;
                }
                const groups = AppInjector.get(GroupService).groups;
                options      = groups.map(group => ({
                    name : group.info?.name as string,
                    value: group.name,
                }));
                break;
            case 'person':
                detail.entityName = 'Person';
                /*this.mapDetails<Person>(details, value, 'email', {
                    ...detail,
                    group      : 'person',
                    canEdit    : true,
                    required   : true,
                    showInTable: false,
                    validators : [Validators.email],
                });*/
                this.mapDetails<Person>(details, value, 'firstName', {
                    ...detail,
                    group      : 'person',
                    canEdit    : true,
                    showInTable: false,
                });
                this.mapDetails<Person>(details, value, 'lastName', {
                    ...detail,
                    group      : 'person',
                    canEdit    : true,
                    showInTable: false,
                });
                detail.groupDirection = 'horizontal';
                detail.showInTable    = false;
                if (!(value as Person)?.addresses?.length) {
                    return;
                }
                for (const _address of value.addresses) {
                    this.mapDetails<Address>(details, _address, 'street', {
                        ...detail,
                        group     : key + '.addresses',
                        groupIndex: 1,
                        tableGroup: true,
                        canEdit   : true,
                    });
                    this.mapDetails<Address>(details, _address, 'houseNumber', {
                        ...detail,
                        group     : key + '.addresses',
                        groupIndex: 1,
                        tableGroup: true,
                        canEdit   : true,
                    });
                    this.mapDetails<Address>(details, _address, 'city', {
                        ...detail,
                        group       : key + '.addresses',
                        groupIndex  : 1,
                        groupByField: true,
                        tableGroup  : true,
                        canEdit     : true,
                    });
                    this.mapDetails<Address>(details, _address, 'countryCode', {
                        ...detail,
                        group     : key + '.addresses',
                        groupIndex: 1,
                        tableGroup: true,
                        canEdit   : true,
                    });
                }
                return;
            case 'profile':
                super.mapDetails<IProfile>(details, value, 'nickname', {
                    ...detail,
                    group: key,
                });
                super.mapDetails<IProfile>(details, value, 'displayName', {
                    ...detail,
                    group: key,
                });
                return;
            case 'businessProfile':
                type               = 'boolean';
                detail.canEdit     = true;
                detail.showInTable = false;
                Object.defineProperty(detail, 'value', {
                    get: (): Nullable<boolean> => AppInjector.get(PublicProfileService).profiles.get(this.name)?.businessProfile || null,
                    set: (businessProfile: boolean): void =>
                    {
                    },
                });
                break;
            case 'verified':
                type               = 'boolean';
                detail.canEdit     = true;
                detail.showInTable = true;
                detail.class = "verified"
                Object.defineProperty(detail, 'value', {
                    get: (): boolean => AppInjector.get(PublicProfileService).profiles.get(this.name)?.verifiedDate instanceof Date,
                    set: (date: Date): void =>
                    {
                    },
                });
                break;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: this.formatKey(level),
            value,
            options,
        }));
    }
}
