import { Gender, Profile as IProfile, SkinType } from '@swan/lib/domain';
import { Change } from '@yukawa/chain-base-angular-domain';


export interface Profile extends IProfile
{
    bgImageId: string;
    businessProfile: boolean;
    change: Change;
    created: Change;
    currentOffersConsent: boolean;
    dateOfBirth: Date;
    description: string;
    displayName: string;
    earlyAccessConsent: boolean;
    gender: Gender;
    imageId: string;
    interests: string[];
    latestNewsConsent: boolean;
    skinType: SkinType;
    skipNotifications: string[];
    username: string;
    verified: string;
    website: string;
}

export enum ProfilePersonalizeSteps
{
    name,
    interests,
    gender,
    birthdate,
    skinType,
    consent,
    drumRoll,
}
