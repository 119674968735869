import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, Entity, EntityRepository, RepoAspectRest } from '@yukawa/chain-base-angular-client';
import { Change } from '@yukawa/chain-base-angular-domain';
import { EntityFilter } from '@yukawa/chain-base-angular-domain/chain/base/core/entity';
import { map, Observable } from 'rxjs';
import { UserService } from './user.service';


export interface IProfileFollow
{
    change: Required<Change>;
    username: string;
    usernameFollowed: string;
}

export class ProfileFollow extends Entity<IProfileFollow> implements IProfileFollow
{
    public readonly change!: Required<Change>;
    public readonly username!: string;
    public readonly usernameFollowed!: string;
    private _following!: boolean;

    constructor(initialData: IProfileFollow)
    {
        super(initialData);
    }

    public get following(): boolean
    {
        return this._following;
    }

    public get key(): string
    {
        return this.usernameFollowed;
    }

    public toJson(): IProfileFollow
    {
        return this.toJsonWithKeys([
            'usernameFollowed',
        ]);
    }

    public updateFromJson(data: IProfileFollow, other: unknown): void
    {
        if (Object.keys(data).length === 0) {
            this._following = false;
        }
        else {
            this.setFromJson(data, [
                'change',
                'username',
                'usernameFollowed',
            ], undefined, {
                change: (change: Change) => ({ ...change, date: new Date(change.date as unknown as string) }),
            });

            this._following = this.change.date.getTime() > 0;
        }
    }
}

@Injectable()
export class ProfileFollowService extends RepoAspectRest<string, ProfileFollow, EntityFilter>
{
    public readonly repository = new EntityRepository<ProfileFollow>({
        createInstanceFrom: this.createInstanceFrom.bind(this),
        keyForEntityCache : this.keyForEntityCache.bind(this),
    });

    public constructor(
        http: HttpClient,
        configService: ConfigService,
        private readonly userService: UserService,
    )
    {
        super(http, configService, 'profileUrl');
    }

    public override load(username: string): Observable<ProfileFollow>
    {
        return this.repository.get(
            this.http.get<ProfileFollow>(this.formatServiceUrl(`/follow/load?username=${username}`)).pipe(
                map(response => response || {
                    change          : {
                        date: new Date(0),
                    },
                    username        : this.userService.user?.username,
                    usernameFollowed: username,
                }),
            ),
            username,
        );
    }

    public follow(follow: ProfileFollow): Observable<ProfileFollow>
    {
        return this.repository.add(
            this.http.post<ProfileFollow>(this.formatServiceUrl(`/follow?username=${follow.usernameFollowed}`), {}),
            follow,
        );
    }

    public unfollow(follow: ProfileFollow): Observable<ProfileFollow>
    {
        return this.repository.update(
            this.http.post<ProfileFollow>(this.formatServiceUrl(`/unfollow?username=${follow.usernameFollowed}`), {}),
            follow,
        );
    }

    protected keyForEntityCache(json: ProfileFollow): string
    {
        return json.key;
    }

    protected createInstanceFrom(json: IProfileFollow): ProfileFollow
    {
        return new ProfileFollow(json);
    }
}
