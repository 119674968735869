<mat-card class="bg-white m-auto rounded-lg max-w-8 mat-elevation-z0 ">
    <mat-card-title class="mb-8">
        <img src="/assets/icons/logo.svg" alt="" class="h-8">
    </mat-card-title>
    <mat-card-content class="text-center">
        {{ "SHARED_PROFILE_INFO" | transloco }}
    </mat-card-content>
    <mat-card-actions>
        <div class="flex flex-col">
            <a [href]="link" class="block" mat-flat-button class="bg-black text-white rounded-full">
                {{ "Download App" | transloco}}
            </a>
        </div>
        <div class="flex flex-col mt-4">
            <button mat-stroked-button (click)="dialogRef.close()" class=" rounded-full">{{ "Not Now" | transloco
                }}</button>
        </div>
    </mat-card-actions>
</mat-card>