import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PublicProfileFilter } from '@swan/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { lastValueFrom, map } from 'rxjs';
import { ImageService } from './image.service';
import { Profile } from './profile.entity';
import { PublicProfile } from './public-profile.model';


@Injectable()
export class PublicProfileService extends RestAspect
{
    public readonly profiles = new Map<string, Profile>();

    constructor(
        http: HttpClient,
        configService: ConfigService,
        private readonly _imageService: ImageService,
    )
    {
        super(http, configService, configService.formatUrl('publicProfileUrl'));
    }

    public async queryByUserName(username: string): Promise<Profile>
    {
        const profile = await lastValueFrom(super.query<PublicProfile>(
            this.formatServiceUrl('/query'),
            { username } as PublicProfileFilter,
        ).pipe(
            map((response) =>
            {
                if (response.rows === 0) {
                    throw new Error(`Public profile '${username}' not found`);
                }
                const item = response.items[0];
                return new Profile(item);
            }),
        ));

        this.profiles.clear();
        this.profiles.set(username, profile);
        return profile;
    }
}
