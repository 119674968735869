import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Profile, PublicProfile } from '@swan/lib/domain';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileResolver implements Resolve<Profile|null> {
  private _configService: ConfigService = inject(ConfigService);
  private _client: HttpClient = inject(HttpClient);
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PublicProfile|null> {
    if(!route.paramMap.get('nickname')){
      return of (null)
    }

    return this._client.get<PublicProfile>(this._configService.formatUrl('profileUrl')+"/open/profiles/",{
      params: {
        nick: route.paramMap.get('nickname')||''
      }
    })
  }
}
