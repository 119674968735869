import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Content, PlaybackToken } from '@swan/lib/content';
import { ContentData } from '../content.resolver';
import { getStoreLink } from '../../shared/layout/layout.component';
import { ConfigService } from '@yukawa/chain-base-angular-client';

@Component({
  selector: 'app-content-player',
  templateUrl: './content-player.component.html',
  styleUrls: ['./content-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('muxVideo') readonly muxVideo: ElementRef<HTMLVideoElement> = null as never;
  @ViewChild('backdrop') readonly backdrop: ElementRef<HTMLCanvasElement> = null as never;
  @ViewChild('storelink') readonly storelink: ElementRef<HTMLAnchorElement> = null as never;

   configService: ConfigService = inject(ConfigService);

  playback!: PlaybackToken;
  content!: Content;
  id : string = '';
  token : string = '';
  context?: CanvasRenderingContext2D|null;
  video: any|null = null;
  interval?:number;
  poster?: HTMLImageElement

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private injector: Injector,
    private ref: ChangeDetectorRef
    ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data:Data) => {
      const contendData = data["data"] as ContentData;
      console.debug('comp',contendData);
      if (contendData.playback) {
        this.playback = contendData.playback;
        this.id = this.playback.videoSrc.split("?")[0]
        this.token = this.playback.videoSrc.split("?")[1].substring(6);
      }
      if(contendData.content){
        this.content = contendData.content;
      }
      if(!this.content){
        this._router.navigate(['/404']);
      }
    }
    )
  }
  ngOnDestroy(): void {
    this.interval && clearInterval(this.interval);
  }

  ngAfterViewInit(): void {
    this.backdrop.nativeElement.width = window.innerWidth;
    this.backdrop.nativeElement.height = window.innerHeight;
    if(this.playback){
      this.initMuxPlayer();
    }
    this.ref.markForCheck();
  }

  initMuxPlayer() {
    this.context = this.backdrop.nativeElement.getContext('2d')!;

    // @ts-ignore
    this.video = this.muxVideo.nativeElement.media.nativeEl;

    this.poster = new Image();
    //@ts-ignore mux is not typed
    this.poster.src = this.content.splashScreen;
    this.poster.crossOrigin = 'anonymous';

    this.poster.onload = () => {
      this.context!.save();
      this.context!.canvas.width = this.backdrop.nativeElement.width;
      this.context!.canvas.height = this.backdrop.nativeElement.height;
      this.context!.filter = 'blur(20px) brightness(0.5)';
      this.context!.drawImage(this.poster!, 0, 0, this.backdrop.nativeElement.width, this.backdrop.nativeElement.height);
      this.context!.restore();
    }

    this.muxVideo.nativeElement.setAttribute('stream-type', 'on-demand');
    this.muxVideo.nativeElement.setAttribute('thumbnail-token', this.content.splashScreen.split('?')[1].substring(6));
    this.muxVideo.nativeElement.setAttribute('playback-id', this.id);
    this.muxVideo.nativeElement.setAttribute('playback-token', this.token);
    this.muxVideo.nativeElement.setAttribute('storyboard-token', this.playback.storyBoard.split('?')[1].substring(6));
    this.muxVideo.nativeElement.setAttribute('metadata-video-title', this.content.info.name);
    this.muxVideo.nativeElement.setAttribute('metadata-viewer-user-id', "anonymous User");

    this.muxVideo.nativeElement.addEventListener('play', ()=>{
      this.drawCanvas(), false;
    });

    window.addEventListener('resize', ()=>{
      this.backdrop.nativeElement.width = window.innerWidth;
      this.backdrop.nativeElement.height = window.innerHeight;
    })

    this.interval = window.setInterval(()=>{
      this.video.style.background = "url('"+this.backdrop.nativeElement.toDataURL()+"')";
    }, 100);
  }
  drawCanvas(): void {

    const ctx = this.context;
    if(this.video.paused || this.video.ended){
      return;
    }
    if(ctx){
      ctx.save();
      ctx.filter = 'blur(20px) brightness(0.5)';
      ctx.drawImage(
        this.video,
        0,
        0,
        this.backdrop.nativeElement.width,
        this.backdrop.nativeElement.height
      );
      ctx.restore();
    }
    window.requestAnimationFrame(this.drawCanvas.bind(this));
  }

    getImageUrl(): string {
        //https://swan.dev.cloud.yukawa.de/api/profile-service/image/download?imageId='+profile.imageId
        return this.configService.getBaseUrl() + '/profile-service/image/download?imageId=' + this.content?.author?.imageId;
    }

  getStoreLink = getStoreLink

}
