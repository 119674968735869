import { NgModule } from '@angular/core';
import { ProfileModule } from '@swan/lib/profile';
import { AdminMuxService } from './admin-mux.service';
import { ContentViewService } from './content-view.service';
import { ContentService } from './content.service';
import { FavoriteService } from './favorite.service';
import { LikedService } from './liked.service';
import { PlayerPositionService } from './player-position.service';
import { PlayerStatisticsService } from './player-statistics.service';


@NgModule({
    imports  : [
        ProfileModule,
    ],
    providers: [
        AdminMuxService,
        ContentService,
        ContentViewService,
        FavoriteService,
        LikedService,
        PlayerPositionService,
        PlayerStatisticsService,
    ],
})
export class ContentModule
{
}
