import { ContentInterest } from '@swan/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { ISelectOption, TreeSelectOptions } from '@yukawa/chain-base-angular-comp/query-table';
import { InterestInfo } from './interest-info.entity';
import { InterestInfoService } from './interest-info.service';


export type InterestSelectOption = Required<ISelectOption<string, InterestInfo>>;

export class InterestInfoOptions<TOption extends InterestSelectOption = InterestSelectOption>
    extends TreeSelectOptions<string, InterestInfo, InterestSelectOption>
{
    public readonly interests = new Array<InterestInfo>();

    constructor(
        values: Array<InterestInfo>,
        options: TOption[],
    )
    {
        super(values, options);
    }

    public get valuesTree(): Array<InterestInfo>
    {
        return this.interests;
    }

    public override mapOption(interest: InterestInfo): TOption
    {
        return (this.findOption(interest) || {
            name          : interest.name,
            value         : interest.id,
            objectValue   : interest,
            objectChildren: interest.interests,
            canSelect     : (): boolean => interest.interests.length === 0,
            canDeselect   : (): boolean => !interest.hasSelectedChildren,
            isSelected    : (): boolean => interest.selected,
            select        : (selected: boolean): void =>
            {
                interest.selected = selected;
            },
        }) as TOption;
    }

    protected override async loadValuesTree(): Promise<void>
    {
        const vodInterests  = this.values as Array<ContentInterest | InterestInfo>;
        const tags          = new Array<InterestInfo>();
        const loadInterests = (interests: Array<InterestInfo>): void =>
        {
            for (const _interest of interests) {
                _interest.selected = vodInterests.some(_vodInterest => (_vodInterest as ContentInterest).interestId === _interest.id);
                if (_interest.selected) {
                    tags.push(_interest);
                }
                loadInterests(_interest.interests);
            }
        };

        this.interests.push(...await AppInjector.get(InterestInfoService).loadInterests());

        loadInterests(this.interests);

        vodInterests.length = 0;
        vodInterests.push(...tags);
    }
}
