import {
    Content as IContent,
    ContentInterest,
    ContentStatistics as IContentStatistics,
    ContentStatus,
    ContentStreamType,
    ContentView as IContentView,
    PublicProfile,
} from '@swan/lib/domain';
import { Change, Info as IInfo } from '@yukawa/chain-base-angular-domain';


export interface ContentStatistics extends IContentStatistics
{
    contentId: number;
    likes: number;
    ticks: number;
    trendWeekly: number;
    trendWeeklyChange: string;
    viewed: number;
}

export interface Info extends IInfo
{
    name: string;
    desc?: string; // Text Area
    shortName?: string;
}

export interface Content extends IContent
{
    author: PublicProfile;
    change: Required<Change>;
    contentId: number;
    created: Required<Change>;
    duration: number;
    extAssetId: string;
    extPlaybackId: string;
    fileName?: string;
    fileSize: number;
    info: Info;
    interests: Required<ContentInterest>[];
    language: string;
    mime: string;
    provider: string;
    recordingDate: Date;
    splashScreen: string;
    stats: ContentStatistics;
    status: ContentStatus;
    thumbnail: string;
    type: string;
}

export interface ContentView extends IContentView
{
    content: Content;
    favorite?: boolean;
    liked?: boolean;
}

export interface UploadContent extends IContent
{
    contentId: number;
    fileName: string;
}

/**
 * Enumerates video list types using the corresponding {@link ContentStreamType} as value.
 */
export enum VideoListTypes
{
    trending    = 'TRENDING',
    watchlist   = 'VIEWED',
    favorites   = 'FAVORITE',
    uploaded    = 'UPLOADED',
    recommended = 'RECOMMENDED',
    followed    = 'FOLLOWED',
    related     = 'RELATED',
    user        = 'USER',
    search      = 'SEARCH',
    playback    = 'PLAYBACK',
    interest    = 'INTEREST'
}

export enum SubtitleTrackStatus {
    READY = "ready",
    PROCESSING = "preparing",
    DISABLED = "disabled",
    ERROR = "errored"
    }
      
    export enum SubtitleTrackType {
    TEXT = "text",
    VIDEO = "video",
    AUDIO = "audio"
    }
    
    export interface SubtitleTrack {
        trackId: string,
        name: string,
        language: string,
        type: string,
        status: SubtitleTrackStatus,
        url: string
    }