import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@yukawa/chain-base-angular-comp/shared';
import { SwanConfirmDialogComponent } from './confirm-dialog.component';


@NgModule({
    declarations: [
        SwanConfirmDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        SharedModule,
        TranslocoModule,
    ],
    entryComponents: [
        SwanConfirmDialogComponent
    ],
})
export class SwanConfirmDialogModule
{
}
