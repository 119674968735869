import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Profile, PublicProfile } from '@swan/lib/domain';
import { getStoreLink } from '../shared/layout/layout.component';
import { ConfigService } from '@yukawa/chain-base-angular-client';

export const LevelColorMap: Map<number, string> = new Map([
  [-1, '#9e9e9e'],
  [1, '#F2D25F'],
  [2, '#FFCC94'],
  [3, '#F2BC9F'],
  [4, '#FCC0C0'],
  [5, '#CF99B0'],
  [6, '#B7A9DE'],
  [7, '#B0C7E3'],
])

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile!: PublicProfile 
  configService: ConfigService = inject(ConfigService);

  constructor(private _route: ActivatedRoute, private _router: Router) { 
    this.profile = _route.snapshot.data['profile'];
    if(!this.profile){
      console.error('Profile not found');
      this._router.navigate(['/404']);
    } 
  }

  ngOnInit(): void {
  }

  levelColor():string{
    return LevelColorMap.get(this.profile.level || -1) || '#9e9e9e'
  }
  getImageUrl(): string {
    //https://swan.dev.cloud.yukawa.de/api/profile-service/image/download?imageId='+profile.imageId
    return this.configService.getBaseUrl() + '/profile-service/image/download?imageId=' + this.profile.imageId
  }

  getStoreLink = getStoreLink
}
